<template>
  <b-modal
    id="modal-role"
    title="Thêm mới vai trò"
    size="md"
    centered
    hide-footer
    @hide="reset"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <b-row>
          <b-col>
            <basic-input
              :required="true"
              label="Tên vai trò"
              name="name"
              placeholder="Nhập tên vai trò"
              :value.sync="name"
              :state="validateState('name')"
              :invalidFeedback="errors.first('name')"
              v-validate="'required|max:20'"
              data-vv-as="Tên vai trò"
              maxlength="20"
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button class="btn mr-4" type="button" @click="cancel">
            Hủy
          </b-button>
          <b-button class="btn btn-success" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalRole',
  data() {
    return {
      loading: false,
      name: null,
    };
  },
  methods: {
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.onClickSaveButton();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async onClickSaveButton() {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post('/Role', {
          name: this.name,
          code: this.name,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.$emit('add-success');
        this.cancel();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$bvModal.hide('modal-role');
    },
    reset() {
      this.name = null;
    },
  },
};
</script>
<style lang=""></style>
